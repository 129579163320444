/* eslint-disable import/no-anonymous-default-export */
import io from 'socket.io-client';

class SocketService {
  socket = null;

  connect() {
    // this.socket = io('http://localhost:5000');
    this.socket = io('https://server-call-fawj.onrender.com');
    
    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  sendMessage(message) {
    if (this.socket) {
      this.socket.emit('message', message);
    }
  }

  onMessage(callback) {
    if (this.socket) {
      this.socket.on('message', callback);
    }
  }

  endChat() {
    if (this.socket) {
      this.socket.emit('endChat');
    }
  }
}

export default new SocketService();
