import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HomeScreen from './components/HomeScreen';
import ChatScreen from './components/ChatScreen';
import CallScreen from './components/CallScreen';
import AudioCallScreen from './components/AudioCallScreen';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DisplayOne from './components/Displaying/DisplayOne';
import DisplayTwo from './components/Displaying/DisplayTwo';
// import { logPageView } from './analytics';

function App() {



  // useEffect(() => {
  //   logPageView();
  // }, []);
  
  return (
    <HelmetProvider>
      {/* Global Meta Tags */}
      <Helmet>
        <title>jilebi.fun</title>
        <meta name="description" content="Connect with people randomly via video, audio, or chat on Jilebi Fun. Enjoy seamless communication and have fun!" />
        <meta name="keywords" content="random chat, video call, audio call, anonymous chat, online chat" />
        <meta name="author" content="Harsha Vardhan Reddy Kunareddy" />
        <meta name="robots" content="index, follow" />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/chat" element={<ChatScreen />} />
          <Route path="/call" element={<CallScreen />} />
          <Route path="/audiocall" element={<AudioCallScreen />} />
          <Route path="/displayone" element={<DisplayOne />} />
          <Route path="/displaytwo" element={<DisplayTwo />} />
        </Routes>
      </Router>
      <ToastContainer />
    </HelmetProvider>
  );
}

export default App;
