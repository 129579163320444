import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBNdnOkDcIjZRr0kybEPhQgRdM3vWuRIsg",
  authDomain: "jilebi-54920.firebaseapp.com",
  projectId: "jilebi-54920",
  storageBucket: "jilebi-54920.appspot.com",
  messagingSenderId: "445923933873",
  appId: "1:445923933873:web:efeb31c339f3e194dc6c90",
  measurementId: "G-2ZSB0ZGP9N"
};

export const FIREBASE_VAPID_KEY = 'BAIleyvJs7q04LChVbvBRoo-TR9ERNhtqHC7s9vR0aVJ_oVzsil7stYTA1y3KDjijEUNcOrDKEUzcuG3yxD8NEg';

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);


const messaging = getMessaging(app);

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token - " + err);
      return null;
    });
};

export const listenForMessages = () => {
  onMessage(messaging, ({ notification }) => {
    new Notification(notification.title, {
      body: notification.body,
      icon: notification.icon,
    });
  });  
}

export { database };