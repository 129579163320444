import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Service worker registration inside useEffect hook
const RegisterServiceWorker = () => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(function(registration) {
          console.log('Hey Coder, You came to see the logs');
        })
        .catch(function(err) {
          console.error('Service Worker registration failed:', err);
        });
    }
  }, []); 
  return null; 
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
      <RegisterServiceWorker />
      <App />
    </>
  </React.StrictMode>
);
