import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';



export default function HomeScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [age, setAge] = useState(null); // State to manage the age
  const [coins, setCoins] = useState(null); // State to manage coins
  const [inputAge, setInputAge] = useState(''); // To store the entered age

  // eslint-disable-next-line
  const [loadingAd, setLoadingAd] = useState(false); // State to manage ad loading
  const [permissionsGranted, setPermissionsGranted] = useState(false); // State to manage permission status
  const [error, setError] = useState(null); // To manage permission errors

  useEffect(() => {
    // Check if age exists in localStorage
    const storedAge = localStorage.getItem('age');
    if (storedAge) {
      setAge(storedAge); // Set age from localStorage if present
    }

    // Retrieve coins from localStorage
    const storedCoins = localStorage.getItem('coins');
    if (storedCoins) {
      setCoins(storedCoins); // Set coins from localStorage if present
    }

    // Show alert message if chat ended
    if (location.state?.chatEndedMessage) {
      alert(location.state.chatEndedMessage);
    }

    
  }, [location.state?.chatEndedMessage]);




  const handleCallPress = ()=>{
    const storedPermissions = localStorage.getItem('permissionsGranted');
    if (storedPermissions === 'true') {
      setPermissionsGranted(true);
      navigate('/call');
    }
    else {
      const requestPermissions = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          if (stream) {
            setPermissionsGranted(true);
            localStorage.setItem('permissionsGranted', 'true');
            navigate('/call');
          }
        } catch (err) {
          setError("You need to allow access to the camera and microphone to proceed.");
        }
      };

      if (!permissionsGranted) {
        const confirmPermission = window.confirm("Allow access to the camera and microphone?");
        if (confirmPermission) {
          requestPermissions();
        }
      }
    }
  }


  const handleAudioCallPress = ()=>{
    const storedPermissions = localStorage.getItem('permissionsGranted');
    if (storedPermissions === 'true') {
      setPermissionsGranted(true);
      navigate('/audiocall');
    }
    else {
      const requestPermissions = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          if (stream) {
            setPermissionsGranted(true);
            localStorage.setItem('permissionsGranted', 'true');
            navigate('/audiocall');
          }
        } catch (err) {
          setError("You need to allow access to the camera and microphone to proceed.");
        }
      };

      if (!permissionsGranted) {
        const confirmPermission = window.confirm("Allow access to the camera and microphone?");
        if (confirmPermission) {
          requestPermissions();
        }
      }
    }
  }

  const handleChatPress = () => {
      navigate('/chat');
  };



  const handleAgeSubmit = () => {
    if (inputAge) {
      localStorage.setItem('age', inputAge);
      localStorage.setItem('coins', 500);
      setAge(inputAge);
      setCoins(500);
    }
  };


  // eslint-disable-next-line
  const handleGetCoins = () => {
    setLoadingAd(true);     
    setTimeout(() => {
      setLoadingAd(false);
    }, 3000);
  };



  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Best Omegle Alternative',
          text: 'Oyy, Look at this Best Omegle Alternative!',
          url: 'https://www.jilebi.fun',
        })
        .then(() => {
          console.log('');
        })
        .catch((error) => {
          console.error('Error sharing content:', error);
        });
    } else {
      console.log('Web Share API is not supported in your browser.');
    }
  };

  if (!age) {
    return (

      <>
        
        <Helmet>
          <title>jilebi.fun - Best Omegle Alternative</title>
          <meta name="description" content="Connect with random users worldwide on jilebi.fun! Enjoy free video and audio calls. The best Omegle alternative for spontaneous conversations." />
          <meta name="keywords" content="jilebi.fun, omegle alternative, random video call, free video call, random chat, audio call, random users, video chat" />
        </Helmet>

        <div style={styles.ageTakerContainer}>
  
        <h1 align="center" style={{color:"#FF5722", fontSize:"60px", fontStyle:"italic" , margin:'0'}}>jilebi.fun</h1>
        <span align="center" style={{color:"#FF5722", fontSize:"18px", fontStyle:"italic", fontWeight:'bold', margin:'2px 0 50px 0' }}>Stranger Chatting - Free</span>

          <h2>Please enter your age</h2>
          <input
            type="number"
            value={inputAge}
            onChange={(e) => setInputAge(e.target.value)}
            placeholder="Enter your age"
            style={styles.input}
          />
          <button onClick={handleAgeSubmit} style={styles.button}>
            Submit
          </button>
        </div>

      </>
      
    );
  }

  return (
    <>


      <Helmet>
        <title>jilebi.fun - Best Omegle Alternative</title>
        <meta name="description" content="Connect with random users worldwide on jilebi.fun! Enjoy free video and audio calls. The best Omegle alternative for spontaneous conversations." />
        <meta name="keywords" content="jilebi.fun, omegle alternative, random video call, free video call, random chat, audio call, random users, video chat" />
      </Helmet>
        
      <div style={styles.container}>
        {error && <div style={styles.error}>{error}</div>}

        <h1 align="center" style={{color:"#FF5722", fontSize:"60px", fontStyle:"italic" , margin:'0'}}>jilebi.fun</h1>
        <span align="center" style={{color:"#FF5722", fontSize:"18px", fontStyle:"italic", fontWeight:'bold', margin:'2px 0 50px 0' }}>Stranger Chatting - Free</span>


        {/* <h2>Coins: {coins}</h2>
        <button onClick={handleGetCoins} style={styles.button}>
          {loadingAd ? 'Loading Ad...' : 'Get Coins'}
        </button> */}
        


        <button 
          onClick={coins > 0 ? handleCallPress : null} 
          style={{ 
            ...styles.button, 
            filter: coins === 0 ? 'blur(2px)' : 'none', 
            pointerEvents: coins === 0 ? 'none' : 'auto' 
          }}
        >
          Free Random Video Call
        </button>



        <button 
          onClick={coins > 0 ? handleAudioCallPress : null} 
          style={{ 
            ...styles.button, 
            filter: coins === 0 ? 'blur(2px)' : 'none', 
            pointerEvents: coins === 0 ? 'none' : 'auto' 
          }}
        >
          Free Random Audio Call
        </button>


        <button 
          onClick={coins > 0 ? handleChatPress : null} 
          style={{ 
            ...styles.button, 
            filter: coins === 0 ? 'blur(2px)' : 'none', 
            pointerEvents: coins === 0 ? 'none' : 'auto' 
          }}
          
        >
          Free Random Chat
        </button>
          
        

        

        <button 
          onClick={handleShare} 
          style={{ 
            ...styles.button, 
          }}
        >
          Share Website
        </button>


      </div>
    </>
    
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:'#f0f0f0' 
  },
  ageTakerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    fontSize: '18px',
    fontWeight:'bold',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    margin: "20px",
    color: "#fff",
    backgroundColor: "#ff9999",
    fontStyle: 'italic'
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};
