import React, { useEffect } from "react";

function DisplayTwo() {


  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `//pl24937948.profitablecpmrate.com/60/07/f9/6007f9ce31d58036bd4fe9ff659e384b.js?${new Date().getTime()}`;

    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);


  // script.src = '//pl24937948.profitablecpmrate.com/60/07/f9/6007f9ce31d58036bd4fe9ff659e384b.js';

  
  return (
    <div style={styles.container}>
        <button className="display" style={styles.button}>Click Me</button>

    </div>

    
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default DisplayTwo;
