/* eslint-disable import/no-anonymous-default-export */
import io from 'socket.io-client';

class SocketServiceCall {
  socket = null;

  connect() {
    // this.socket = io('http://localhost:5000');
    this.socket = io('https://server-call-for-call.onrender.com');
    
    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  
}

export default new SocketServiceCall();
