import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SocketService from '../services/SocketService'; // Adjust the import based on your socket setup
import { requestForToken,database,listenForMessages } from './firebaseConfig';
import { push, ref, set, get } from 'firebase/database';


export default function LoadingScreen() {
  const navigate = useNavigate();
  const socket = SocketService.socket; // Access the socket instance directly

  // eslint-disable-next-line
  const [isWaiting, setIsWaiting] = useState(true);
  
  // eslint-disable-next-line
  const [isWaitingNotify, setIsWaitingNotify] = useState(true);
  const [popup, setPopup] = useState(false);

  const [token, setToken] = useState("");

  // const [timerStarted, setTimerStarted] = useState(false);

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);





  
  useEffect(() => {

    
    const StoredTokenLocal = localStorage.getItem("StoredToken");
    if(StoredTokenLocal){
      setToken(StoredTokenLocal)
      // console.log("Token Stored = ", StoredTokenLocal);
      
    }
    else{
      // console.log("NO Stored Token Available");
      console.log("");
    }


    if (isWaiting) {
      const timer = setTimeout(() => {
        setPopup(true);
        SocketService.disconnect();
        // console.log('15 sec waited');
      }, 25000);

      return () => clearTimeout(timer); // Clean up the timer when component unmounts or isWaiting changes
    }
  }, [isWaiting]);


  const getToken = async () => {

    setLoading(true); // Start loading


    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await requestForToken();
      if (token) {
        setToken(token);
        // console.log("token: ",token);
        localStorage.setItem("StoredToken",token);
        storeToken(token)
      }
    }
    else{
      alert("You Blocked the notications. Allow in settings.")
    }

    setLoading(false); // End loading
  }

  const storeToken = (token) => {
    const tokenRef = push(ref(database, 'tokens')); // Generate unique key
    set(tokenRef, {
      token: token,
      timestamp: new Date().toISOString()
    })
      .then(() => {
        console.log("Token successfully stored in Firebase Realtime Database.");
      })
      .catch((error) => {
        console.error("Error storing token: ", error);
      });
  };



  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);



    // Create and append the ad script to a specific container
    const adContainer = document.getElementById('ad-space');

    if (adContainer) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        atOptions = {
          'key' : 'a75d2fa52b21762d01c320948e2c54af',
          'format' : 'iframe',
          'height' : 50,
          'width' : 320,
          'params' : {}
        };
      `;
      adContainer.appendChild(script);

      const scriptSrc = document.createElement('script');
      scriptSrc.src = "//www.topcpmcreativeformat.com/a75d2fa52b21762d01c320948e2c54af/invoke.js";
      scriptSrc.type = 'text/javascript';
      adContainer.appendChild(scriptSrc);
    }


    return () => {

      if (adContainer) {
        adContainer.innerHTML = ''; // Clean up ad container content
      }
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (socket) {
        socket.disconnect();
      }
    };



    
  }, [socket]);



  useEffect(() => {
    listenForMessages(); // Start listening for messages

    if (isWaitingNotify) {
      // Start a 5-second timeout
      const timer = setTimeout(() => {
        notifyAllUsers();
      }, 5000); 

      return () => clearTimeout(timer); // Cleanup timer
    }
    // eslint-disable-next-line
  }, []);


  const notifyAllUsers = async () => {
    // Fetch all FCM tokens from Firebase Realtime Database
    const tokensSnapshot = await get(ref(database, '/tokens'));
    const tokens = tokensSnapshot.val();

    if (tokens) {
      const tokenArray = Object.values(tokens).map(tokenObj => tokenObj.token);
      sendNotificationToUsers(tokenArray);
      setIsWaitingNotify(false);
    }
    else{
      console.log("Tokens not retrieved");
    }
  };

  const sendNotificationToUsers = async (tokenArray) => {
    
    const payload = {
      title: 'jilebi.fun',
      body: 'Somebody is Online!!!',
      tokens: tokenArray,
      existingToken: localStorage.getItem("StoredToken"),
      urlForNavigation: "chat"
    };

    try {
      // Replace with your actual API endpoint to handle FCM notifications
      // console.log("existing token = ", payload.existingToken);
      
      await fetch('https://firebasenotificationserver-t2px.onrender.com/send-notification', {
      // await fetch('http://localhost:5000/send-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleBack = () => {
    if (socket) {
      socket.disconnect(); // Disconnect the socket when going back
    }
    navigate('/');
  };

  // const handleCall = () => {
  //   if (socket) {
  //     socket.disconnect(); // Disconnect the socket when canceling
  //   }
  //   navigate('/call');
  // };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Best Omegle Alternative',
          text: 'Oyy, Look at this Best Omegle Alternative!!',
          url: 'https://www.jilebi.fun',
        })
        .then(() => {
          console.log('');
        })
        .catch((error) => {
          console.error('Error sharing content:', error);
        });
    } else {
      console.log('Web Share API is not supported in your browser.');
    }
  };



  

  return (
    <div>
      {popup === true ? (
            !token ? (
              
              <div style={styles.container}>
                <p style={styles.text}>No User Online!</p>
                <p style={styles.text}>We will notify you when user is online!</p>
                <p style={styles.text}>Please Allow Notifications!</p>
                <div style={styles.buttonContainer}>
                  <button onClick={getToken} style={styles.button}>
                    {loading ? "Allowing..." : "Okay Allow Notifications."}
                  </button>
                </div>
                <div style={styles.buttonContainer}>

                  <button onClick={handleBack} style={styles.button}>Go Back</button>
                </div>
              </div>
            ) : (
                <div style={styles.container}>
                  <p style={styles.text}>No User Online!</p>
                  <p style={styles.text}>Share the Website With Your Bestfriend</p>
                  
                  <div style={styles.buttonContainer}>
                    <button onClick={handleShare} style={styles.button}>Share Now</button>
                    <button onClick={handleBack} style={styles.button}>Go Back</button>
                    </div>
                </div>
            )
            
      ) : (
        <div style={styles.container}>
          <div id="ad-space" style={styles.adContainer}></div>
          <p style={styles.text}>Waiting for another user for chat...</p>
          <div style={styles.buttonContainer}>
            
            <button onClick={handleBack} style={styles.button}>Go Back</button>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
  },
  text: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  button: {
    margin: '10px 10px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  adContainer: {
    width: '320px',
    height: '50px',
    position: 'fixed',
    top: '85%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // border: '1px solid #ccc',
  },
};




