import React from "react";
import { useNavigate } from "react-router-dom";

function DisplayOne() {

  const navigate = useNavigate();
  return (
    <div style={styles.container}>
      <a href="https://www.profitablecpmrate.com/sdss2z9je?key=59415b43d41efcc580caceb52d744afd" target="_top" >
        <button className="display" style={styles.button}>Click Me</button>
      </a>
        <button className="displaytwoClass" style={styles.button} onClick={()=>navigate('/displaytwo')}>Click Me</button>

      
    </div>

    
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default DisplayOne;
