import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SocketService from '../services/SocketService';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import { Helmet } from 'react-helmet-async';


export default function ChatScreen() {
  const [messages, setMessages] = useState([]);
  const [isWaiting, setIsWaiting] = useState(true);
  const [user, setUser] = useState(null);
  const [newMessage, setNewMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const initUser = async () => {
      let storedUser = localStorage.getItem('user');
      if (!storedUser) {
        storedUser = {
          _id: Math.floor(Math.random() * 1000000) + 1,
          name: `User ${Math.floor(Math.random() * 1000000) + 1}`,
        };
        localStorage.setItem('user', JSON.stringify(storedUser));
      } else {
        storedUser = JSON.parse(storedUser);
      }
      setUser(storedUser);
    };

    initUser();
  }, []);

  // Function to reduce the coins by 1 and save the updated value in localStorage

  // eslint-disable-next-line
  const reduceCoins = () => {
    let storedCoins = localStorage.getItem('coins');
    if (storedCoins) {
      let reducedCoins = Math.max(0, storedCoins - 1); // Ensure it doesn't go below 0
      localStorage.setItem('coins', reducedCoins);
    }
  };

  useEffect(() => {
    if (user) {
      loadMessages();
      const socket = SocketService.connect();

      socket.on('connectToChat', (chatMessages) => {
        // reduceCoins(); // Decrease the coins when the chat connects
        setIsWaiting(false);
        setMessages(chatMessages.map(msg => ({
          ...msg,
          id: msg.user ? msg.user._id : null,
          senderName: msg.user ? msg.user.name : 'Anonymous',
        })));
      });

      SocketService.onMessage((newMessages) => {
        const updatedMessages = newMessages.map(msg => ({
          ...msg,
          id: msg.user ? msg.user._id : null,
          senderName: msg.user ? msg.user.name : 'Anonymous',
        }));
        setMessages((previousMessages) => [...previousMessages, ...updatedMessages]);
        saveMessages([...messages, ...updatedMessages]);
      });

      socket.on('endChat', () => {
        toast.info('Chat Ended', {
          position: 'bottom-center',
          autoClose: 1500,
          onClose: () => {
            endChat(); // Navigate to Home and clear chat
          }
        });
      });

      return () => {
        SocketService.disconnect();
      };
    }

    // eslint-disable-next-line
  }, [user]);

  const loadMessages = () => {
    try {
      const storedMessages = localStorage.getItem('chatMessages');
      if (storedMessages) {
        setMessages(JSON.parse(storedMessages).map(msg => ({
          ...msg,
          id: msg.user ? msg.user._id : null,
          senderName: msg.user ? msg.user.name : 'Anonymous',
        })));
      }
    } catch (error) {
      console.error('Failed to load messages:', error);
    }
  };

  const saveMessages = (newMessages) => {
    try {
      localStorage.setItem('chatMessages', JSON.stringify(newMessages));
    } catch (error) {
      console.error('Failed to save messages:', error);
    }
  };

  const onSend = () => {
    if (!newMessage.trim()) return; // Prevent sending empty messages

    const messageObj = {
      id: user ? user._id : null,
      message: newMessage,
      senderName: user ? user.name : 'Anonymous',
    };

    const updatedMessages = [...messages, messageObj];
    setMessages(updatedMessages);
    saveMessages(updatedMessages);
    SocketService.sendMessage([messageObj]);
    setNewMessage(''); // Clear the input field
  };

  const endChat = () => {
    SocketService.endChat(); // Notify the backend about ending the chat
    localStorage.removeItem('chatMessages');
    navigate('/');
  };

  if (isWaiting) {
    return <LoadingScreen />;
  }

  return (


    <>

      <Helmet>
        <title>jilebi.fun - Best Omegle Alternative</title>
        <meta name="description" content="Connect with random users worldwide on jilebi.fun! Enjoy free video and audio calls. The best Omegle alternative for spontaneous conversations." />
        <meta name="keywords" content="jilebi.fun, omegle alternative, random video call, free video call, random chat, audio call, random users, video chat" />
      </Helmet>


      <div style={styles.container}>
        <div style={styles.chatContainer}>
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                ...styles.messageContainer,
                justifyContent: msg.id === user?._id ? 'flex-end' : 'flex-start',
              }}
            >
              <div
                style={{
                  ...styles.messageBubble,
                  backgroundColor: msg.id === user?._id ? '#007bff' : '#fff',
                  color: msg.id === user?._id ? '#fff' : '#000',
                }}
              >
                {msg.message}
              </div>
            </div>
          ))}
        </div>
        <div style={styles.inputContainer}>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            style={styles.input}
            placeholder="Type a message..."
          />
          <button onClick={onSend} style={styles.sendButton}>Send</button>
        </div>
        <button style={{margin:'20px auto 50px auto', width:'200px'}} onClick={endChat}>End Chat</button>
        <ToastContainer />
      </div>
    </>
    
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxWidth: '900px',
    backgroundColor: 'whitesmoke',
    margin: '0 auto',
    borderRadius: '20px'
  },
  chatContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: '10px',
  },
  messageContainer: {
    display: 'flex',
    marginBottom: '10px',
    padding: '0 10px',
  },
  messageBubble: {
    borderRadius: '15px',
    padding: '10px',
    maxWidth: '70%',
    wordBreak: 'break-word',
  },
  inputContainer: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #ddd',
  },
  input: {
    flex: 1,
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  sendButton: {
    marginLeft: '10px',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
};
